<nx-checkbox-group [(ngModel)]="selectedPackageCodes"
                   name="selectedPackageCodes"
                   (blur)="onBlurHandler($event)">
    <nx-accordion variant="light" *ngFor="let dictionaryPackage of packages">
        <nx-expansion-panel>
            <nx-expansion-panel-header>
                <nx-expansion-panel-title>
                    <nx-checkbox (checkboxChange)="changeSelectedPackagesHandler($event)"
                                 [value]="dictionaryPackage.code"
                                 (click)="$event.stopPropagation()"
                                 [disabled]="disabled"
                                 class="u-margin-top-md">
                        <span headline="subsection-xsmall" class="break-word">{{dictionaryPackage.description}}
                            : {{dictionaryPackage.premium | currency:'PLN': 'symbol-narrow': '0.0-2'}}</span>
                    </nx-checkbox>
                </nx-expansion-panel-title>
            </nx-expansion-panel-header>
            <div class="overflow-x">
                <table nxTable zebra class="nx-margin-bottom-m">
                    <thead>
                    <tr nxTableRow>
                        <th nxHeaderCell>
                            {{'OFFER.PACKAGE_TABLE.HEADER.RISK_DESCRIPTION' | translate}}
                        </th>
                        <th nxHeaderCell>
                            {{'OFFER.PACKAGE_TABLE.HEADER.SUM_GROSS' | translate}}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr nxTableRow *ngFor="let risk of dictionaryPackage.risks">
                        <td nxTableCell>{{risk.description}}</td>
                        <td nxTableCell>
                            {{risk.sumGross > 0 ? (risk.sumGross | currency:'PLN': 'symbol-narrow': '0.0-2') : ('UTILS.GC' | translate)}}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </nx-expansion-panel>
    </nx-accordion>
</nx-checkbox-group>
