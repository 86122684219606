<h5 class="u-text-center u-text-weight-semibold">
    <span
        *ngIf="productCode === ProductCode.AOZ
        && (insuredWith === InsuredWith.FAMILY_MEMBER || insuredType === InsuredType.FAMILY_MEMBER) ;else yourTotalCostMessage">
        {{'OFFER.FAMILY_TOTAL_COST_INFO' | translate}}
    </span>
    <span>{{totalCost | currency:'PLN': 'symbol-narrow': '0.2-2'}}</span>
</h5>

<ng-template #yourTotalCostMessage>
    {{'OFFER.TOTAL_COST_INFO' | translate}}<ng-container *ngIf="dynamicSum"> {{'OFFER.DYNAMIC_SUM_TOTAL_COST_INFO' | translate}}</ng-container>:
</ng-template>
