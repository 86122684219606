import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { NxCheckboxChangeEvent } from '@aposin/ng-aquila/checkbox';

import { Package } from '@core/model';

@Component({
    selector: 'ed-packages',
    templateUrl: './packages.component.html',
    styleUrls: ['./packages.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => PackagesComponent),
        multi: true
    }]
})
export class PackagesComponent implements ControlValueAccessor {

    @Input()
    public packages: Package[];
    @Input()
    public disabled: boolean;

    public selectedPackageCodes: string[];

    private mutateNgModel: Package[];
    private propagateToModelChangeEvent: (value: Package[]) => void;
    private propagateToModelTouchEvent: () => void;

    public onBlurHandler(_: Event): void {
        this.propagateToModelTouchEvent();
    }

    public changeSelectedPackagesHandler(nx: NxCheckboxChangeEvent): void {
        if (nx.checked) {
            this.selectPackage(nx.value);
        } else {
            this.unselectPackage(nx.value);
        }
        this.propagateToModelChangeEvent(this.mutateNgModel);
    }

    public writeValue(selectedPackages: Package[]): void {
        this.mutateNgModel = !!selectedPackages && Array.isArray(selectedPackages) ? selectedPackages : [];
        this.selectedPackageCodes = this.mutateNgModel.map(selectedPackage => selectedPackage.code);
    }

    public registerOnChange(fn: (value: Package[]) => void): void {
        this.propagateToModelChangeEvent = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateToModelTouchEvent = fn;
    }

    private selectPackage(packageCode: string): void {
        const packagetoAdd = this.getPackageWith(packageCode);
        const clone = cloneDeep(packagetoAdd);
        this.mutateNgModel.push(clone);
    }

    private unselectPackage(packageCode: string): void {
        const index = this.getSelectedPackageIndex(packageCode);
        this.mutateNgModel.splice(index, 1);
    }

    private getSelectedPackageIndex(packageCode: string): number {
        return this.mutateNgModel.findIndex(p => p.code === packageCode);
    }

    private getPackageWith(packageCode: string): Package {
        return this.packages.find(p => p.code === packageCode);
    }
}
